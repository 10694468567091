import request from '@/utils/request'


// 查询服务者身份表列表
export function listIdentity(query) {
  return request({
    url: '/identity/identity/list',
    method: 'get',
    params: query
  })
}


// 查询服务者身份表分页
export function pageIdentity(query) {
  return request({
    url: '/identity/identity/page',
    method: 'get',
    params: query
  })
}

// 查询服务者身份表详细
export function getIdentity(data) {
  return request({
    url: '/identity/identity/detail',
    method: 'get',
    params: data
  })
}

// 新增服务者身份表
export function addIdentity(data) {
  return request({
    url: '/identity/identity/add',
    method: 'post',
    data: data
  })
}

// 修改服务者身份表
export function updateIdentity(data) {
  return request({
    url: '/identity/identity/edit',
    method: 'post',
    data: data
  })
}

// 删除服务者身份表
export function delIdentity(data) {
  return request({
    url: '/identity/identity/delete',
    method: 'post',
    data: data
  })
}
