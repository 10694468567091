<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle == '新增' ? $t('通用.新增') : $t('通用.修改') }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item :label="$t('经纪人/服务者.身份设置.身份')" prop="identity" >
        <a-input v-model="form.identity" :placeholder="$t('经纪人/服务者.身份设置.请输入身份')" />
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.身份设置.上级身份,顶级不选')" prop="pid" >
<!--        <a-input v-model="form.pid" placeholder="请选择上级身份 顶级不选" />-->
        <a-select :placeholder="$t('经纪人/服务者.身份设置.请输入')" show-search v-model="form.pid"
                  option-filter-prop="children" :filterOption="filterOption">
          <a-select-option v-model="item.id" v-for="(item, index) in courseList" :key="index">
            {{ item.identity }}
          </a-select-option>
        </a-select>

      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.身份设置.图片')" prop="picture" >
        <file-upload type="image" :defaultList="form.imgArr" :count="1"
                     @input="getImg($event, 'picture')"></file-upload>
      </a-form-model-item>
      <a-form-model-item :label="$t('经纪人/服务者.身份设置.备注')" prop="remark" >
        <a-input v-model="form.remark" :placeholder="$t('经纪人/服务者.身份设置.请输入备注')" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getIdentity, addIdentity, updateIdentity, listIdentity} from '@/api/identity/identity'
import {guid} from "@/utils/ruoyi";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      courseList:[],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        imgCount: 1,
        imgArr: [],
        identity: null,

        pid: null,

        picture: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        identity: [
          { required: true, message: '身份不能为空', trigger: 'blur' }
        ],
        // pid: [
        //   { required: true, message: '类型 0为顶级不能为空', trigger: 'blur' }
        // ],
        // picture: [
        //   { required: true, message: '图片不能为空', trigger: 'blur' }
        // ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        identity: null,
        pid: null,
        picture: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.getSuperiors()
    },

    /** 查询上级名称 */
    getSuperiors() {
      listIdentity({pid:0}).then(Response => {
          console.log(Response)
          this.courseList = Response.data
        }
      )
    },



    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getIdentity({"id":id}).then(response => {
        this.form = response.data

        let that = this;
        this.form.imgArr = []
        this.form.imgArr.push({
          uid: guid(8, 10),
          name: that.form.picture,
          status: 'done',
          halfUrl: that.form.picture,
          url: that.form.picture,
          path: that.form.picture
        })

        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateIdentity(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addIdentity(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    /******************** 上传图片数据处理 start ********************/
    /**前端 需要将图片格式处理成数组，方便数据回显 */
    setImgData(field, img) {
      let fieldImgArr = []
      if (img.indexOf(",") === -1) {
        // 单图
        if (this.form[`${field}Count`] === 1) {
          if (img) {
            fieldImgArr.push({
              uid: guid(8, 10),
              name: img,
              status: 'done',
              halfUrl: img,
              url: img,
              path: img
            })
          }
        }
      } else {
        // 多图
        if (this.form[`${field}Count`] > 1) {
          let imgArr = img.split(",")
          if (imgArr.length) {
            imgArr.map(item => {
              fieldImgArr.push({
                uid: guid(8, 10),
                name: img,
                status: 'done',
                halfUrl: img,
                url: item,
                path: item
              })
            })
          }
        }
      }
      return fieldImgArr
    },
    /**上传图片成功回调 */
    getImg(info, fieldName) {
      console.log('info', info)
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].url
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info.map(ele => { return ele.url }).join(",")
          this.form[`${fieldName}Arr`] = info
        }
      }
    },
    /** 点击视频 弹窗打窗口播放视频*/
    async handlePreview(file) {
      this.previewImage = this.form.video || file.video;
      this.previewVisible = true;
      this.$nextTick(() => {
        this.$refs.myVideo.play()
      })
    },
    handleCancel() {
      this.previewVisible = false;
    },
    /******************** 上传图片数据处理 end   ********************/
  }
}
</script>
